.App {
  text-align: center;
  align-items: center;
}

#navbar {
  position: sticky;
  zIndex: 1003;
  background: #007ac2 !important;
  color: #deebff;
}


/* This adjust the SignIn font size */
:root {
  --amplify-components-fieldcontrol-small-font-size: 1rem;
  --amplify-components-button-font-size: 1rem;
  --amplify-components-fieldcontrol-font-size: 1rem;
  --amplify-components-tabs-item-font-size: 1rem;
  --amplify-components-authenticator-container-width-max: 22rem;
  --amplify-line-heights-medium : 1rem;
 
}

.line-3 hr {
  height: 1px;
  border: 0;
  background: #808080;
  margin-right: 20px;
}

.amplify-label {
  color: #808080;
  font-size: small;
  font-weight: bold;
}

.amplify-heading {
  font-weight: bolder;
}

.amplify-input {
  font-size: small;
}

/*Button disabled */
.btncolor {
  background-color: #D3D3D3;
  cursor: not-allowed;
  pointer-events: none;
  color: white !important;

}

#headerclr {
  padding-bottom: 10px;
  position: sticky;
  background-color: white;
}

.topnav {
  overflow: hidden;
  position: sticky;
  zIndex: 1003;
  color: white;
  background:unset;
  background-color: #007ac2 !important;
  height: 70px;
}

[data-amplify-authenticator] [data-amplify-container] {
  padding-top: 60px;
}

#logoImage {
  height: 60px;
  width: 140px;
  float: left;
}

#titlenav {
  color: white;
  float: left;
  padding-left: 20px;
  padding-top: 35px;
  font-size: larger;
}

.tooltip {
  position: relative;
  display: inline-block;
}

/* Reset Password tooltip*/
.tooltip .tooltiptext {
  visibility: hidden;
  width: 180px;
  height: 35px;
  color: grey;
  text-align: left;
  font-size: 7px;
  border-width: 1px;
  font-weight: bolder;
  font-family: sans-serif;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -120px;
  line-height: normal;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.textstyle{
  font-weight: bolder;
  text-align: right;
  padding-right: 20px;
  font-size: smaller;
}

.footer{
   position: fixed;
   left: 0;
   bottom: 0;
   width: 100%;
   background-color: #007ac2;
   height: 20px;
}

[data-amplify-form]{
  padding-top: 28px !important;
}

.amplify-heading--3 {
    font-size: var(--amplify-components-heading-4-font-size);
}


/*This CSS hide the signUp tab*/
.amplify-tabs {
  display: none;
}

.uploadcontainer{
  padding-top: 50px !important;
  padding: 30px;
}

/*upload page logo*/
.uploadtopnav > div > div > div > header > div > div > a > img {
  max-height: 52px !important;
}


  form-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  label-container {
    margin-right: 10px;
    width: 100px;
    text-align: right;
  }

  
  input-container {
    width: 200px;
  }
  
  input-select {
    width: 100%;
  }

/* styles.css */
.my-custom-selectfield .amplify-select {
  border-radius: 1;
}